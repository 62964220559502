const fields = document.querySelectorAll('.setting-form__sub-fields');
if (fields.length > 0) {
  fields.forEach((field) => {
    const list = field.querySelector('.setting-form__list');
    if (list) {
      const checkboxList = list.querySelectorAll('input[type="checkbox"]')
      const toggle = field.querySelector('.setting-form__toggle');
      const inner = field.querySelector('.setting-form__list');
      if (inner && toggle) {
        toggle.addEventListener('click', (evt) => {
          evt.preventDefault();
  
          if (!field.classList.contains('setting-form__sub-fields--active')) {
            field.classList.add('setting-form__sub-fields--active');
            inner.style.maxHeight = `${inner.scrollHeight}px`;
          } else {
            field.classList.remove('setting-form__sub-fields--active');
            inner.style.maxHeight = '';
          }
        });
      }
  
      window.addEventListener('load', () => {
        const activemenuLists = document.querySelectorAll('.setting-form__sub-fields--active');
        activemenuLists.forEach((menuList) => {
          const inner = menuList.querySelector('.setting-form__list');
          if (inner) {
            inner.style.maxHeight = `${inner.scrollHeight}px`;
          }
        });
      });
      
  
      const checkboxForAll = field.querySelector('.setting-form__all').querySelector('input');
      if (checkboxForAll) {
        checkboxForAll.addEventListener('change', () => {
          if (checkboxForAll.checked) {
            checkboxList.forEach((el) => {
              el.checked = true;
            });
          } else {
            checkboxList.forEach((el) => {
              el.checked = false;
            });
          }
        });
        checkboxList.forEach((el) => {
          el.addEventListener('change', (evt) => {
            if (!evt.checked) {
              checkboxForAll.checked = false;
            }
          });
        });
      }
    }
  });
}
